import React from 'react';
import './LazyBackground.scss';
import './mobile/index.scss';
import VisibilitySensor from 'react-visibility-sensor';
import Skeleton from '@material-ui/lab/Skeleton';
import { Fade } from 'react-reveal';

type Props = {
    src?: string;
    bgDark?: boolean;
    height?: number;
    windowWidth?: number;
    width?: any;
    className?: string;
};
type State = any;
class LazyBackground extends React.Component<Props, State> {
  _isMounted: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = { src: null, test: false };
    this.onChange = this.onChange.bind(this);
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillUpdate(nextProps: Props, nextState: State) {
    if (nextState.test && !nextState.src) {
      const { src } = this.props;
      const imageLoader:{[index:string]:any} = new Image();
      imageLoader.src = src;
      imageLoader.onload = () => {
        if (this._isMounted) {
          this.setState({ src });
        }
      };
    }
  }

  onChange(isVisible: any) {
    if (isVisible && !this.state.test) {
      this.setState({ test: true });
    }
  }

  render() {
    const {
      windowWidth, children, height, width,
    } = this.props;
    const elementProps = { ...this.props };
    const bgDarkVal = this.props.bgDark ? 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ' : '';
    delete (elementProps as any).windowWidth;
    delete (elementProps as any).dispatch;
    return (
      <div className="LazyBackground">
        {(this.state.src === null) ? (
          <VisibilitySensor onChange={this.onChange}>
            <Skeleton animation="wave" variant="rect" height={height} style={{ width: `${width}` || '100%' }} className="lazy-background__skeleton" />
          </VisibilitySensor>
        ) : (
          <Fade>
            <div
              {...elementProps}
              style={{
                background: `${bgDarkVal} ${this.state.src ? `url(${this.state.src})` : ''}`,
              }}
            >
              {children}
            </div>
          </Fade>
        )}
      </div>
    );
  }
}
export default LazyBackground;
